import React from "React";
import { NavLink } from "react-router-dom";

import { Trans } from "react-i18next";
import i18n from "../../i18n";
import {
  ROLEMAP,
  CONTROL_PANEL_PERMISSIONS,
  LANGUAGES,
} from "../../../constants/CONSTANTS";
import "../../../css/navbar/bootstrapNavbarAll.css";
import { setLocaleForUser } from "../../redux/actions/authAction";
import { connect } from "react-redux";
@connect((store) => {
  return {};
})
export default class NavbarBootAll extends React.Component {
  constructor(props) {
    super(props);
    this.activateSearch = this.activateSearch.bind(this);
    this.state = {
      searchText: "inactive",
      controlPanelPermissionsExist: false,
      lang: "",
      showDiv: false,
    };
  }

  componentDidMount() {
    var userPermissions = JSON.parse(
      localStorage.getItem("userObj")
    ).permissions;
    var controlPanelPermissionsExist = false;
    userPermissions.map((obj) => {
      if (CONTROL_PANEL_PERMISSIONS.includes(obj)) {
        controlPanelPermissionsExist = true;
      }
    });
    this.setState({ controlPanelPermissionsExist });
  }

  activateSearch(e) {
    if (e.target.value != "") {
      this.setState({ searchText: "active" });
    } else {
      this.setState({ searchText: "inactive" });
    }
  }
  launchBuilder = () => {
    this.props.handler(true);
  };

  handleChangeLang = (lang) => {
    console.log("************this changes the lang!");
    //this.setState({locale:"fr"})
    //this.setState({ locale: "en" });
    // store.dispatch({type: "SET_LOCALE"})
    // localStorage.setItem("locale","fr");
    // store.dispatch(updateIntl({
    //   locale:'fr',
    //   French,
    // }))
    // var lang = localStorage.getItem("i18nextLng");
    // if(lang==null || lang=="fr"){
    //   localStorage.setItem("i18nextLng","en");
    //   i18n.changeLanguage('en');
    // }else{
    //   localStorage.setItem("i18nextLng","fr");
    //   i18n.changeLanguage('fr');
    // }
    // var lang = localStorage.getItem("locale");
    // console.log("lang="+lang)
    if (lang == "en") {
      i18n.changeLanguage("fr");
      localStorage.setItem("locale", "fr");
      this.props.dispatch(setLocaleForUser("fr"));
      //this.setState({lang:'fr'})
    } else {
      i18n.changeLanguage("en");
      localStorage.setItem("locale", "en");
      this.props.dispatch(setLocaleForUser("en"));
      //this.setState({lang:'en'})
    }
    // i18n.changeLanguage(lang);
    // localStorage.setItem("locale",lang);
    //this.props.dispatch(setLocaleForUser(lang));
    window.location.reload(true); //to refresh current page

    console.log("***href=" + window.location.href);
    //window.location.href=window.location.origin;
  };

  changeStyle1OnHover = () => {
    var changeLang = document.getElementById("changeLang");
    changeLang.style.textDecoration = "underLine";
    changeLang.style.color = "white";
    this.setState({ showDiv: true });
  };

  changeStyle1Back = () => {
    var changeLang = document.getElementById("changeLang");
    changeLang.style.textDecoration = "none";
    changeLang.style.color = "#9a9db7";
  };

  changeStyle2OnHover = () => {
    var signout = document.getElementById("signout");
    signout.style.textDecoration = "underLine";
    signout.style.color = "white";
  };

  changeStyle2Back = () => {
    var signout = document.getElementById("signout");
    signout.style.textDecoration = "none";
    signout.style.color = "#9a9db7";
  };

  render() {
    return (
      <nav class="navbar no-icon-nav navbar-expand-lg navbar-dark bg-dark bd-navbar">
        <a class="navbar-brand h-100" href="#" style={{ borderRadius: "5px" }}>
          <img src="../../images/fodxpert_logo.jpg" height="100%" width="120px" />
        </a>
        <NavLink
          to="/logout"
          class="navbar-toggler-logout d-md-none"
          style={{ position: "absolute", right: "10px" }}
        >
          <i class="fa fa-circle-o"></i> Sign Out
        </NavLink>

        <div
          class="navbar-nav-scroll navbar-collapse pl-5"
          id="navbarTogglerDemo02"
        >
          <ul class="navbar-nav mr-auto mt-2 bd-navbar-nav mt-lg-0 navbar-navigation-menu">
            {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf(
              "overview"
            ) !== -1 ? (
              <li class="nav-item">
                <NavLink
                  class="nav-link"
                  activeClassName="active"
                  to="/overview"
                >
                  <i class="fas fa-columns"></i>{" "}
                  <span>
                    <Trans i18nKey="titlebar.overview" />
                  </span>
                </NavLink>
              </li>
            ) : null}
            {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf(
              "myscreens"
            ) !== -1 ? (
              <li class="nav-item">
                <NavLink
                  class="nav-link"
                  activeClassName="active"
                  to="/myscreen/null"
                >
                  <i class="fas fa-desktop"></i>{" "}
                  <span>
                    <Trans i18nKey="titlebar.myscreens"></Trans>
                  </span>
                </NavLink>
              </li>
            ) : null}
            {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf(
              "myads"
            ) !== -1 ? (
              <li class="nav-item">
                <NavLink class="nav-link" activeClassName="active" to="/myads">
                  {" "}
                  <span>
                    {JSON.parse(
                      localStorage.getItem("userObj")
                    ).permissions.indexOf("usealias") !== -1 ? (
                      <Trans i18nKey="titlebar.mynotices" />
                    ) : (
                      <Trans i18nKey="titlebar.myads" />
                    )}
                  </span>
                </NavLink>
              </li>
            ) : null}
            {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf(
              "confroomscheduler"
            ) !== -1 ? (
              <li class="nav-item">
                <NavLink
                  class="nav-link"
                  activeClassName="active"
                  to="/confroomscheduler"
                >
                  <i class="fa fa-chart-bar"></i>{" "}
                  <span>Conference Room Scheduler</span>
                </NavLink>
              </li>
            ) : null}
            {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf(
              "scheduler"
            ) !== -1 ? (
              <li class="nav-item">
                <NavLink
                  class="nav-link"
                  activeClassName="active"
                  to="/scheduler"
                >
                  <i class="fa fa-chart-bar"></i>{" "}
                  <span>
                    <Trans i18nKey="titlebar.scheduler" />
                  </span>
                </NavLink>
              </li>
            ) : null}
            {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf(
              "playlist"
            ) !== -1 ? (
              <li class="nav-item">
                <NavLink
                  class="nav-link"
                  activeClassName="active"
                  to="/playlist"
                >
                  <i class="fas fa-columns"></i>{" "}
                  <span>
                    <Trans i18nKey="titlebar.playlist" />
                  </span>
                </NavLink>
              </li>
            ) : null}
            {JSON.parse(localStorage.getItem("userObj")).userRole!=="RestaurantUserCreation"?
              <li class="nav-item">
                <NavLink
                  class="nav-link"
                  activeClassName="active"
                  to="/restaurantplaylist"
                >
                  <i class="fas fa-columns"></i>{" "}
                  <span>
                    Restaurant Playlist
                  </span>
                </NavLink>
              </li>
            :null}
            {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf(
              "designlayout"
            ) !== -1 ? (
              <li class="nav-item">
                <NavLink class="nav-link" activeClassName="active" to="/design">
                  <i class="fas fa-columns"></i>{" "}
                  <span>
                    <Trans i18nKey="titlebar.design" />
                  </span>
                </NavLink>
              </li>
            ) : null}
            {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf(
              "adreport"
            ) !== -1 ? (
              <li class="nav-item">
                <NavLink
                  class="nav-link"
                  activeClassName="active"
                  to="/reports"
                >
                  <i class="fa fa-chart-bar"></i>{" "}
                  <span>
                    <Trans i18nKey="titlebar.reports" />
                  </span>
                </NavLink>
              </li>
            ) : null}
            {this.state.controlPanelPermissionsExist ? (
              <li class="nav-item">
                <NavLink class="nav-link" activeClassName="active" to="/admin">
                  <i class="fas fa-file-alt"></i>{" "}
                  <span>
                    <Trans i18nKey="titlebar.controlpanel" />
                  </span>
                </NavLink>
              </li>
            ) : null}
            {JSON.parse(localStorage.getItem("userObj")).permissions.indexOf(
              "usermgt"
            ) !== -1 ||
            JSON.parse(localStorage.getItem("userObj")).permissions.indexOf(
              "assignscreens"
            ) !== -1 ||
            JSON.parse(localStorage.getItem("userObj")).permissions.indexOf(
              "schedulemgt"
            ) !== -1 ||
            JSON.parse(localStorage.getItem("userObj")).permissions.indexOf(
              "accesspermissions"
            ) !== -1 ||
            JSON.parse(localStorage.getItem("userObj")).permissions.indexOf(
              "rolemanagement"
            ) !== -1 ? (
              <li class="nav-item">
                <NavLink
                  class="nav-link"
                  activeClassName="active"
                  to="/adminoptions"
                >
                  <i class="fas fa-file-alt"></i>{" "}
                  <span>
                    <Trans i18nKey="titlebar.admin" />
                  </span>
                </NavLink>
              </li>              
            ) : null}
            {JSON.parse(localStorage.getItem("userObj")).role=="000" || 
              JSON.parse(localStorage.getItem("userObj")).userRole=="RestaurantUserCreation"?
            <li class="nav-item">
                <NavLink
                  class="nav-link"
                  activeClassName="active"
                  to="/restuser"
                >
                  <i class="fas fa-file-alt"></i>{" "}
                  <span>
                    Restaurant User
                  </span>
                </NavLink>
              </li>
              :null}
          </ul>
          <ul class="navbar-nav ml-auto action-items d-none d-md-flex">
            <li class="nav-item  d-none  align-items-center justify-content-center">
              <div class="navbar-search">
                <button type="submit" class="btn btn-search">
                  <i class="fa fa-search"></i>
                </button>
                <input type="text" name="search" placeholder="search" />
              </div>
            </li>

            <li class="nav-item">
              <a class="nav-link user-avatar d-inline-flex align-items-center">
                <span class="avatar w-32">
                  <i
                    class="fas fa-user-circle"
                    style={{ fontSize: "30px" }}
                  ></i>
                </span>
                <span
                  class="nav-text d-flex flex-column pointer pl-2 text-left"
                  style={{ lineHeight: "1" }}
                >
                  <span class="text-uppercase">
                    {JSON.parse(localStorage.getItem("userObj")).userName}&nbsp;
                    {/*JSON.parse(localStorage.getItem("userObj")).lastName*/}
                  </span>
                  <small class="text-uppercase">
                    {JSON.parse(localStorage.getItem("userObj")).userRole}
                  </small>
                </span>
                {/*ROLEMAP[JSON.parse(localStorage.getItem("userObj")).role]*/}
              </a>
              <div class="treeview-menu-block">
                <ul class="treeview-menu">
                  <li>
                    <NavLink to="/logout" activeClassName="active">
                      <i class="fa fa-circle-o"></i> Sign Out
                    </NavLink>
                  </li>
                </ul>
              </div>
              {/* <div class="treeview-menu-block">
                <ul class="treeview-menu">
                  <li>
                    <div
                      id="changeLang"
                      activeClassName="active"
                      style={{
                        height: "10px",
                        color: "#9a9db7",
                        textDecoration: "none",
                        backgroundColor: "transparent",
                        cursor: "pointer",
                        fontSize: "14px",
                        marginLeft: "14px",
                      }}
                      onMouseOver={() => this.changeStyle1OnHover()}
                      onMouseOut={() => this.changeStyle1Back()}
                      onClick={() =>
                        this.handleChangeLang(localStorage.getItem("locale"))
                      }
                    >
                      <Trans i18nKey="menu.changelang" />
                    </div>
                  </li>
                  <li>
                    <i class="fa fa-circle-o"></i>
                    <NavLink
                      to="/logout"
                      activeClassName="active"
                      id="signout"
                      style={{ color: "#9a9db7" }}
                      onMouseOver={() => this.changeStyle2OnHover()}
                      onMouseOut={() => this.changeStyle2Back()}
                    >
                      {" "}
                      <Trans i18nKey="menu.signout" />
                    </NavLink>
                  </li>
                </ul>
              </div> */}
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
