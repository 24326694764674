import React from "react";
import {
  login,
  sendEmailForReset,
  redirectCanva,
} from "../../redux/actions/authAction.js";
import ActionButton from "../formcomponent/ActionButton";
import { connect } from "react-redux";
import Input from "../formcomponent/Input";
import ComponentLoader from "../../components/uilib/ComponentLoader";
import DialogBox from "../../components/DialogBox";
import Breakpoint from "../../components/uilib/Breakpoint";

import detectBrowserLanguage from "detect-browser-language";
import { LANGUAGES } from "../../../constants/CONSTANTS";
import i18n from "../../i18n";
import { Trans } from "react-i18next";
import moment from "moment";
import moment1 from "moment-range";
import axios from "../../utils/axiosInstance"

@connect((store) => {
  return {
    isAuthenticating: store.auth.isAuthenticating,
    isAuthenticated: store.auth.isAuthenticated,
    initialState: store.auth.initialState,
    sendingResetEmail: store.auth.sendingResetEmail,
    sentResetEmail: store.auth.sentResetEmail,
    resetEmailError: store.auth.resetEmailError,
  };
})
export default class Login_Form extends React.Component {
  constructor(props) {
    super(props);
    this.showLoading = false;
    this.state = {
      userName: "",
      password: "",
      resetRequest: false,
      lang: "",
    };
  }

  componentDidMount() {
    console.log("loginform", "starttime==" + Date.now());
    // var url = new URL(window.location.href);
    // var userObj = {};
    // userObj.user = url.searchParams.get("user");
    // userObj.state = url.searchParams.get("state");
    // userObj.time = url.searchParams.get("time");
    // userObj.brand = url.searchParams.get("brand");
    // userObj.extensions = url.searchParams.get("extensions");
    // userObj.signatures = url.searchParams.get("signatures");

    // if(userObj.user!==""){
    //   console.log("request for Canva!")
    //   console.log("url="+window.location.href)
    //   console.log("query part="+window.location.href.split("?")[1])
    //   window.location.href=window.location.origin+"/#/login/?"+window.location.href.split("?")[1]
    //   //this.props.dispatch(redirectCanva(userObj))
    // }
    var langInBrowser = detectBrowserLanguage().substring(0, 2);
    console.log("browser lang=" + langInBrowser);
    if (langInBrowser !== "fr") {
      langInBrowser = "en";
    }
    this.setState({ lang: langInBrowser });
    localStorage.setItem("locale", langInBrowser);
    i18n.changeLanguage(langInBrowser);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    console.log("search="+search);
    //For redirecting to My screens Start
    const redirect = params.get("redirect");
    const authCode = params.get("authCode");
    const screenID = params.get("screenID");
    console.log("***authCode="+authCode)
    if(redirect!==null && redirect=="Myscreens"){
      console.log("redirect to My screens");
  
    axios.post(`/api/verifyTokenForUser`  ,
    {
      'token': authCode,    
    }).then(response => {
      console.log("response.userName="+JSON.stringify(response.data));
      this.setState({userName:response.data.userName,password:response.data.pwd});
      localStorage.setItem("selectedScreenID",screenID);
      this.login();
    
    // if(response.data.userName=="test"){
    //   //this.props.history.push(`/myscreen`);
    //   window.location.href=window.location.origin+"/#/myscreen";
    // }
    }).catch(error => {
      this.setState({errorUploading:true})
      // handle your error
    });        
  }
//For redirecting to My screens End

  //For redirecting to Loading page for Instagram
  const code = params.get("code");
  
  if(code!==null){
    this.showLoading = true;
  }

  }

  login = () => {
    console.log("***in login");
    console.log("loginform", "starttime==" + Date.now());
    var lang = localStorage.getItem("locale");

    if (this.state.userName !== "" && this.state.password !== "") {
      this.props.dispatch(login(this.state));
    }
    console.log("loginform", "endtime==" + Date.now());
  };
  resetPassword = () => {
    console.log("resetPassword loginform", "starttime==" + Date.now());

    if (this.state.userName !== "") {
      this.setState({ resetRequest: true });
    }
    console.log("resetPassword loginform", "endtime==" + Date.now());
  };
  confirmResetPassword = () => {
    console.log("loginform", "starttime==" + Date.now());
    if (this.state.userName !== "") {
      this.props.dispatch(
        sendEmailForReset(this.state.userName, localStorage.getItem("locale"))
      );
      this.setState({ resetRequest: false });
    }
    console.log("loginform", "endtime==" + Date.now());
  };

  setFieldValue = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };
  componentWillReceiveProps(nextProps) {
    console.log("loginform", "starttime==" + Date.now());
    console.log("sendingResetEmail=" + nextProps.sendingResetEmail);
    console.log("sentResetEmail=" + nextProps.sentResetEmail);
    console.log("resetEmailError=" + nextProps.resetEmailError);
    console.log("reset request=" + this.state.resetRequest);
    console.log("loginform", "endtime==" + Date.now());
  }

  resetDialog = () => {
    this.setState({ resetRequest: false });
  };

  handleLanguageChange = (e) => {
    console.log("in handle lang=" + e.target.value);
    localStorage.setItem("locale", e.target.value);
    i18n.changeLanguage(e.target.value);
    this.setState({ lang: e.target.value });
  };

  render() {
    console.log("loginform", "starttime==" + Date.now());
    return (
      <div>
      {this.showLoading?<ComponentLoader/>:
      <div
          style={{
            height: "96vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              height: "10%",
              width: "100%",
              textAlign: "right",
              color: "white",
            }}
          >
            <br />
            {LANGUAGES.map((obj) => {
              return (
                <label style={{ color: "#4fbfbd" }}>
                  <input
                    type="radio"
                    value={obj.value}
                    checked={this.state.lang == obj.value}
                    onChange={this.handleLanguageChange}
                  />
                  {obj.label}&nbsp;&nbsp;&nbsp;&nbsp;
                </label>
              );
            })}
          </div>
          <div
            class="login-form d-flex no-shadow   card"
            style={{
              height: "70%",
              width: "20%",
              background: "transparent",
              margin: "auto",
              overflowX: "hidden",
              overflowY: "hidden",
            }}
          >
            <div
              class="d-flex align-items-center justify-content-center"
              style={{ height: "80px" }}
            >
              <img
                src="images/fodxpert_logo.jpg"
                height="90%"
                width="52%"
                alt="Card image cap"
              />
            </div>
            <div
              class="flex-1 d-flex align-items-start justify-content-center"
              style={{ width: "100%", height: "100%" }}
            >
              <div
                class="card-body d-flex flex-column"
                style={{ width: "100%" }}
              >
                <div style={{ width: "100%" }}>
                  <Input
                    text={i18n.t("login.username")}
                    ref="userName"
                    name="userName"
                    validator={true}
                    theme={"transparent"}
                    value={this.state.userName}
                    onChange={this.setFieldValue}
                    mandatory
                    emptyMessage="Application name can't be empty"
                  />
                </div>
                <div>
                  <Input
                    text={i18n.t("login.password")}
                    ref="password"
                    name="password"
                    validator={true}
                    theme={"transparent"}
                    type="password"
                    value={this.state.password}
                    onChange={this.setFieldValue}
                    mandatory
                    emptyMessage="Application name can't be empty"
                  />
                </div>
                <div>
                  <ActionButton
                    containerClass={`${
                      this.props.isAuthenticating ? "loading" : null
                    } btn btn-outline-theme`}
                   clickHandler={() => this.login()} //commented to disable
                    style={{ width: "100%" }}
                  >
                    <Trans i18nKey="login.logintext" />
                  </ActionButton>
                </div>
                <div style={{ paddingTop: "10px" }}>
                  <span style={{ textAlign: "center", margin: "auto" }}>
                    <button
                      style={{
                        border: "0px",
                        textDecoration: "underline",
                        color: "#4fbfbd",
                        background: "transparent",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => this.resetPassword()}
                      //disabled={true} //added to disable
                    >
                      {this.state.resetRequest}
                      <Trans i18nKey="login.reset" />
                    </button>
                  </span>
                </div>
                <div class="error-msg" style={{ height: "70px" }}>
                  {!this.props.initialState && !this.props.isAuthenticated ? (
                    <p style={{ color: "#B22222" }}>
                      <Trans i18nKey="login.loginfailed" />
                    </p>
                  ) : null}
                  {this.props.sendingResetEmail ? <ComponentLoader /> : null}
                  {this.props.sentResetEmail ? (
                    <p style={{ color: "#4fbfbd" }}>
                      <Trans i18nKey="login.resetmessage" />
                    </p>
                  ) : null}
                  {this.props.resetEmailError ? (
                    <p style={{ color: "#B22222" }}>
                      <Trans i18nKey="login.validusernamemsg" />
                    </p>
                  ) : null}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    minHeight: "10%",
                  }}
                >
                  <div style={{ width: "50%", textAlign: "left" }}>
                    <a
                      href="https://www.intelisa.in/"
                      target="_blank"
                      style={{ color: "#4fbfbd" }}
                    >
                      <Trans i18nKey="login.aboutus" />
                    </a>
                  </div>
                  <div style={{ width: "50%", textAlign: "right" }}>
                    <a
                      href="https://www.intelisa.in/#get-free-trial"
                      target="_blank"
                      style={{ color: "#4fbfbd" }}
                    >
                      <Trans i18nKey="login.contactus" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
        {this.state.resetRequest ? (
          <DialogBox
            msg={i18n.t("login.resetconfirm") + this.state.userName + "?"}
          >
            <button
              class="btn btn-danger"
              onClick={() => this.confirmResetPassword()}
            >
              <Trans i18nKey="login.yes" />
            </button>
            <button
              class="btn btn-light"
              onClick={() => this.setState({ resetRequest: false })}
            >
              <Trans i18nKey="login.cancel" />
            </button>
          </DialogBox>
        ) : null}
      
      </div>
    );
  }
}

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    fontSize: "14px",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "14px",
  }),
};
