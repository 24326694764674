import i18n from "../js/i18n";
export const FORM_CONFIG = { theme: "boxed", validTick: "d-none" };

export const DATA_PROVIDER = [
  { value: "Data", label: i18n.t("dataprovider.data") },
  { value: "WIFI", label: i18n.t("dataprovider.wifi") },
];

export const ROLEMAP = {
  "000": i18n.t("rolemap.sysadmin"),
  "001": i18n.t("rolemap.type1"),
  "002": i18n.t("rolemap.type2"),
  "003": i18n.t("rolemap.type3"),
};
// export const SCREEN_TYPE=[
//    { value: 'Indoor', label: 'Indoor' },
//  { value: 'Outdoor', label: 'Outdoor' }

// ]

export const ZONE_TYPE = [
  { value: "North", label: "North" },
  { value: "South", label: "South" },
  { value: "East", label: "East" },
  { value: "West", label: "West" },
];

export const ZONE_TYPE_WADMIN = [
  { value: "Landscape", label: "Landscape" },
  { value: "Portrait", label: "Portrait" },
  { value: "Landscape_180", label: "Landscape_180" },
  { value: "Portrait_270", label: "Portrait_270" },
];
export const TIMEPICKER = [
  { value: "00", label: "00" },
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
];
export const SLOT_MAPPING = {
  roundRobin: "Round Robin",
  slot1: "00:00 - 01:00",
  slot2: "01:00 - 02:00",
  slot3: "02:00 - 03:00",
  slot4: "03:00 - 04:00",
  slot5: "04:00 - 05:00",
  slot6: "05:00 - 06:00",
  slot7: "06:00 - 07:00",
  slot8: "07:00 - 08:00",
  slot9: "08:00 - 09:00",
  slot10: "09:00 - 10:00",
  slot11: "10:00 - 11:00",
  slot12: "11:00 - 12:00",
  slot13: "12:00 - 13:00",
  slot14: "13:00 - 14:00",
  slot15: "14:00 - 15:00",
  slot16: "15:00 - 16:00",
  slot17: "16:00 - 17:00",
  slot18: "17:00 - 18:00",
  slot19: "18:00 - 19:00",
  slot20: "19:00 - 20:00",
  slot21: "20:00 - 21:00",
  slot22: "21:00 - 22:00",
  slot23: "22:00 - 23:00",
  slot24: "23:00 - 24:00",
};

export const SOCIAL_MEDIA_TYPE = [
  { value: "INSTAGRAM", label: i18n.t("socialmediamgt.instagram") },
  { value: "INSTAGRAM_STORIES", label: i18n.t("socialmediamgt.stories") },
  { value: "TWITTER", label: i18n.t("socialmediamgt.twitter") },
  { value: "LIVEURL", label: i18n.t("socialmediamgt.liveurl") },
  //{ value: 'RSSFEEDS', label: 'RSS FEEDS' },
];

export const USER_ROLES_FOR_SYSADMIN = [
  { value: "001", label: i18n.t("rolemap.type1") },
  { value: "002", label: i18n.t("rolemap.type2") },
  { value: "003", label: i18n.t("rolemap.type3") },
];

export const USER_ROLES_FOR_LEVEL1 = [
  { value: "002", label: i18n.t("rolemap.type2") },
  { value: "003", label: i18n.t("rolemap.type3") },
];

export const USER_ROLES_FOR_LEVEL2 = [
  { value: "003", label: i18n.t("rolemap.type3") },
];

export const USER_ACCESS_PERMISSIONS_LABELS = {
  overview: i18n.t("accesspermissions.overview"),
  myscreens: i18n.t("accesspermissions.myscreens"),
  myads: i18n.t("accesspermissions.myads"),
  confroomscheduler: i18n.t("accesspermissions.confroomscheduler"),
  scheduler: i18n.t("accesspermissions.scheduler"),
  schedulerdocs: i18n.t("accesspermissions.schedulerdocs"),
  playlist: i18n.t("accesspermissions.playlist"),
  multipleplaylistitems: i18n.t("accesspermissions.multipleplaylistitems"),
  //reports : "Reports",
  onoffreport: i18n.t("accesspermissions.onoffreport"), //on off report will be displayed
  adreport: i18n.t("accesspermissions.adreport"),
  playlistreport: i18n.t("accesspermissions.playlistreport"),
  customerreport: i18n.t("accesspermissions.customerreport"),
  customerdetailreport: i18n.t("accesspermissions.customerdetailreport"),
  covid: i18n.t("accesspermissions.covid"),
  screenmgt: i18n.t("accesspermissions.screenmgt"),
  adgroupmgt: i18n.t("accesspermissions.adgroupmgt"),
  admanagement: i18n.t("accesspermissions.admanagement"),
  screentypemgt: i18n.t("accesspermissions.screentypemgt"),
  locationmgt: i18n.t("accesspermissions.locationmgt"),
  screensavermgt: i18n.t("accesspermissions.screensavermgt"),
  socialmedia: i18n.t("accesspermissions.socialmedia"),
  syncmanagement: i18n.t("accesspermissions.syncmanagement"),
  tagmgt: i18n.t("accesspermissions.tagmgt"),
  salesgraph: i18n.t("accesspermissions.salesgraph"),
  splitscreenmgt: i18n.t("accesspermissions.splitscreenmgt"),
  usermgt: i18n.t("accesspermissions.usermgt"),
  assignscreens: i18n.t("accesspermissions.assignscreens"),
  schedulemgt: i18n.t("accesspermissions.schedulemgt"),
  accesspermissions: i18n.t("accesspermissions.accesspermissions"),
  logindetails: i18n.t("accesspermissions.logindetails"),
  twosteplogin: i18n.t("accesspermissions.twosteplogin"),
  consolidatedonoffreport: i18n.t("accesspermissions.consolidatedonoffreport"), //data for on off screens will be retrieved
  usealias: i18n.t("accesspermissions.usealias"),
  rolemanagement: i18n.t("accesspermissions.rolemanagement"),
  assignSWversiontoscreens: i18n.t(
    "accesspermissions.assignSWversiontoscreens"
  ),
  designlayout: i18n.t("accesspermissions.designlayout"),
  widgets: i18n.t("accesspermissions.widgets"),
  checkexpiry: i18n.t("accesspermissions.checkexpiry"),
  offscreendetails: i18n.t("accesspermissions.offscreendetails"),
  jsonupload: i18n.t("accesspermissions.jsonupload"),
  restaurantmgt: i18n.t("accesspermissions.restaurantmgt"),
};

export const CONTROL_PANEL_PERMISSIONS = [
  "screenmgt",
  "adgroupmgt",
  "admanagement",
  "screentypemgt",
  "locationmgt",
  "screensavermgt",
  "socialmedia",
  "downloadlogs",
  "tagmgt",
  "syncmanagement",
  "salesgraph",
  "splitscreenmgt",
  "logindetails",
  "offscreendetails",
  "jsonupload"
];

export const BACKGROUND_COLORS = [
  //'#7bc0f7','#3b8ad9','#f18226','#ffdb69','#61737b','#a6b3b3','#e24b26','#a88add','#0cc2aa'
  "#58d6b3",
  "#4ec7dd",
  "#5235c4",
  "#3c5997",
  "#e6bc24",
  "f18226",
  "#E6F69D",
  "#FF6961",
  "#BE61CA",
  "868686",
];

export const SPLITSCREEN_TYPES = {
  layout50_50: "50-50-Ticker",
  layout60_40: "60-40-Ticker",
  layout65_35: "65-35-Ticker",
  layout70_30: "70-30-Ticker",
  layout80_20: "80-20-Ticker",
  layoutFullscreen_Ticker: "Fullscreen-Ticker",
  layoutFullscreen: "Fullscreen-No Ticker",
  layout5050_TBTicker: "50-50-Ticker",
};

export const LANGUAGES = [
  { value: "en", label: i18n.t("language.english") },
  // { value: "fr", label: i18n.t("language.french") },
];

/*export const INTELISA_FOLDER_USERS=[
      '5dca83744dd468314c374353',
      '5ddd0b7ed58c322efc4bf0ed',
      '5def4cb2fa6ca42f596ae47e',
      '5ce6eef2623fe666061924de',
      '5d8dd49e6bf19056f02b1eed',
      '5e2e60a04c248c0a6c8eac38',
      '5e2e60f64c248c0a6c8eac3a',
      '5e2e9ed738368d55a5c8d0b4',
      '5e315fba9d3b9417b7660e41',
      '5e3938650071a75b353f2cee',
      '5e4132e76a1e9b42f2d0ed53',
      '5e42a9f636bcda3a120dc312',
      '5e4a809c5b023d5856ebc68e',
      '5e4a82055b023d5856ebc690',
      '5e58d8f421bacf63ffd2980b',
      '5e58dcc921bacf63ffd2980d',
      '5e63290cf1670d0b241be1ed',
      '5e6eedd90233e14345308614',
      '5e787d5591920c5dde0232c4',
      '5e85807eb3751946006e6ab6',
      '5ee30b83dc499a089670a261'
    ]*/

export const canvaDesigns = [
  // {label:"Presentation",value:"Presentation",icon:"https://category-public.canva.com/icons/presentation.svg"},
  {
    label: "Landscape Video",
    value: "Presentation",
    icon: "https://category-public.canva.com/icons/icon-video.svg",
  },
  {
    label: "Portrait Video",
    value: "InstagramStory",
    icon: "https://category-public.canva.com/icons/noun_118538.svg",
  },
  {
    label: "Landscape Image",
    value: "DesktopWallpaper",
    icon: "https://category-public.canva.com/icons/icon-image.svg",
  },
  {
    label: "Portrait Image",
    value: "InstagramStory",
    icon: "https://category-public.canva.com/icons/icon-phoneWallpaper.svg",
  },
  // {label:"Logo",value:"Logo",icon:"https://category-public.canva.com/icons/Logo-01.svg"}
];

export const HOST = "https://contentmanager.fodxpert.com";
//export const HOST = "http://localhost:3000";
// export const HOST="http://65.1.135.211:3000"
 //export const HOST="http://3.109.28.221:3000"
 //export const HOST="http://13.235.34.195:3000"; //Fodxpert New server

  //export const API_KEY = "AIzaSyBWpH2n7K3h33gYf43jHWEUc4OcBqN"; //for local
export const API_KEY = "AIzaSyBWpH2n7K3h33gYf43jHWEUc4OcBqN06yU"; //for prod
//export const API_KEY ="AIzaSyDDX99XLMvQ7LxlAXZsMUXjKvzMmUlaluE" //for dev,local
