
export default function reducer(state = {
playReportFetched:false,
playReportStatusFetched:false,
playReportData:[],
playReportStatusData:[],
offScreensDataFetched:false,
offScreensData:[],
onOffScreensFetched:false,
onOffScreensData:[],
onOffReportMailSent:false,
weeklyAdsDataFetched:false,
weeklyAdsData:[],
avgFreqData:[],  
avgFreqDataFetched:false,
adReportData:[],  
adReportDataFetched:false,
adGroups:[],  
adGroupsFetched:false,
adNames:[],  
adNamesFetched:false,
noScheduleData:[],  
noScheduleDataFetched:false,
playReportSlaveData:[],
playReportTickerData:[],
covidData:[],  
covidDataFetched:false,
covidaccessData:[],  
covidaccessFetched:false,
covidscreensData:[],  
covidscreensFetched:false,
covidDetails:[],  
covidDetailsFetched:false,
covidReportMailSent:false,
customerReportData:[],  
customerReportDataFetched:false,
dataUrl:null,  
dataUrlFetched:false,
footerDataUrl:null,  
footerDataUrlFetched:false,
headerDataUrl:null,  
headerDataUrlFetched:false
}, action) {

  switch (action.type) {
    case "FETCH_PLAYREPORTSTATUS":{
      return {
        ...state,
      
      }
    }
    case "FETCH_PLAYREPORTSTATUS_SUCCESS":{
      return {
        ...state,playReportStatusData:action.payload,  playReportStatusFetched:true
        
      }
    }
    case "FETCH_PLAYREPORT":{
      return {
        ...state,
      
      }
    }
    case "FETCH_PLAYREPORT_SUCCESS":{
      console.log("In playreport success action.payload="+JSON.stringify(action.payload))
      return {
        ...state,playReportData:action.payload,  playReportFetched:true
      }
    }
    case "FETCH_PLAYREPORT_SLAVE_SUCCESS":{
      return {
        ...state,playReportSlaveData:action.payload,  playReportFetched:true
      }
    }
    case "FETCH_PLAYREPORT_TICKER_SUCCESS":{
      return {
        ...state,playReportTickerData:action.payload,  playReportFetched:true
      }
    }
    case "FETCH_OFFSCREEN_DATA":{
      return {
        ...state,
      
      }
    }
    case "FETCH_OFFSCREEN_DATA_SUCCESS":{
      return {
        ...state,offScreensData:action.payload,  offScreensDataFetched:true
      }
    }
    case "FETCH_NOSCHEDULE_DATA":{
      return {
        ...state,
      
      }
    }
    case "FETCH_NOSCHEDULE_DATA_SUCCESS":{
      return {
        ...state,noScheduleData:action.payload,  noScheduleDataFetched:true
      }
    }
    case "FETCH_ONOFFSCREENS":{
      return {
        ...state,onOffReportMailSent:false
      
      }
    }
    case "FETCH_ONOFFSCREENS_SUCCESS":{
      return {
        ...state,onOffScreensData:action.payload,  onOffScreensFetched:true
      }
    }

    case "SEND_ONOFFREPORTMAIL":{
      return {
        ...state,
      
      }
    }
    case "SEND_ONOFFREPORTMAIL_SUCCESS":{
      return {
        ...state,  onOffReportMailSent:true
      }
    }

    case "FETCH_WEEKLYADS_DATA":{
      return {
        ...state,
      
      }
    }
    case "FETCH_WEEKLYADS_DATA_SUCCESS":{
      return {
        ...state,weeklyAdsData:action.payload,  weeklyAdsDataFetched:true
      }
    }
    case "FETCH_AVGFREQ_DATA":{
      return {
        ...state,
      
      }
    }
    case "FETCH_AVGFREQ_DATA_SUCCESS":{
      return {
        ...state,avgFreqData:action.payload,  avgFreqDataFetched:true
      }
    }

    case "FETCH_ADREPORT_DETAILS":{
      return {
        ...state,
      
      }
    }
    case "FETCH_ADREPORT_DETAILS_SUCCESS":{
      return {
        ...state,adReportData:action.payload,  adReportDataFetched:true
      }
    }
    case "FETCH_ADGROUPS":{
      return {
        ...state,
      
      }
    }
    case "FETCH_ADGROUPS_SUCCESS":{
      return {
        
        ...state,adGroups:action.payload,  adGroupsFetched:true
      }
    }
    
    case "FETCH_ADNAMES":{
      return {
        ...state,
      
      }
    }
    case "FETCH_ADNAMES_SUCCESS":{
      return {
        ...state,adNames:action.payload,  adNamesFetched:true
      }
    }

    case "FETCH_COVID_DATA":{
      return {
        ...state,onOffReportMailSent:false
      
      }
    }
    case "FETCH_COVID_DATA_SUCCESS":{
      return {
        ...state,covidData:action.payload,  covidDataFetched:true
      }
    }
    
    case "FETCH_ACCESS_COVID":{
      return {
        ...state,onOffReportMailSent:false
      
      }
    }
    case "FETCH_ACCESS_COVID_SUCCESS":{
      return {
        ...state,covidaccessData:action.payload,  covidaccessFetched:true
      }
    }

    case "FETCH_SCREENS_COVID":{
      return {
        ...state,onOffReportMailSent:false
      
      }
    }
    case "FETCH_SCREENS_COVID_SUCCESS":{
      return {
        ...state,covidscreensData:action.payload,  covidscreensFetched:true
      }
    }

    case "FETCH_DETAILS_COVID":{
      return {
        ...state,onOffReportMailSent:false
      
      }
    }
    case "FETCH_DETAILS_COVID_SUCCESS":{
      return {
        ...state,covidDetails:action.payload,  covidDetailsFetched:true
      }
    }
    case "SEND_COVIDREPORTMAIL":{
      return {
        ...state,
      
      }
    }
    case "SEND_COVIDREPORTMAIL_SUCCESS":{
      return {
        ...state,  onOffReportMailSent:true
      }
    }

    case "FETCH_CUSTOMER_REPORT":{
      return {
        ...state,onOffReportMailSent:false
      
      }
    }
    case "FETCH_CUSTOMER_REPORT_SUCCESS":{
      return {
        ...state,customerReportData:action.payload,  customerReportDataFetched:true
      }
    }
    case "FETCH_DATA_URL":{
      return {
        ...state//,onOffReportMailSent:false
      
      }
    }
    case "FETCH_DATA_URL_SUCCESS":{
      return {
        ...state,dataUrl:action.payload,  dataUrlFetched:true
      }
    }
    case "FETCH_FOOTER_DATA_URL":{
      return {
        ...state//,onOffReportMailSent:false
      
      }
    }
    case "FETCH_FOOTER_DATA_URL_SUCCESS":{
      return {
        ...state,footerDataUrl:action.payload,  footerDataUrlFetched:true
      }
    }
    case "FETCH_HEADER_DATA_URL":{
      return {
        ...state//,onOffReportMailSent:false
      
      }
    }
    case "FETCH_HEADER_DATA_URL_SUCCESS":{
      return {
        ...state,headerDataUrl:action.payload,  headerDataUrlFetched:true
      }
    }
    case "SEND_CUSTOMERREPORTMAIL":{
      return {
        ...state,
      
      }
    }
    case "SEND_CUSTOMERREPORTMAIL_SUCCESS":{
      return {
        ...state,  onOffReportMailSent:true
      }
    }

  }
  return state;
}
