import React, { Component, Suspense, lazy } from "react";
import ComponentLoader from "../components/uilib/ComponentLoader";

export default class RedirectToFodxpert extends Component {

constructor(props){
    super(props);
    this.state={

    }
}

render(){
    return(
        <div style={{width:'100vw',height:'100vh',display:"flex",flexDirection:"column"}}>
        <div style={{height:"20%",alignItems:"baseline",border:"0px solid black"}}>
            <ComponentLoader/>
        </div>
        <div style={{height:"80%",display:"flex",flexDirection:"row",justifyContent:"center"
            ,alignItems:"start",fontSize:"28px",fontWeight:"bold"}}>
            Loading ...</div>
        </div>
    )
}

}