import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router";
import IdleTimer from "react-idle-timer"; //for session timeout
import IdleTimeOutModal from "../pages/Auth/IdleTimeOutModal";

import Navbar from "../components/navbars/NavbarBootAll";
import Footer from "../components/navbars/Footer";
import PageLoader from "../components/uilib/PageLoader";
import Ad_Upload_Progress from "../components/admin/Ad_Upload_Progress";

const Dashboard = lazy(() => import("./Dashboard"));
const Admin = lazy(() => import("./Admin"));
const AdminOptions = lazy(() => import("./AdminOptions"));
const RestUser = lazy(() => import("./RestUser"));
const Report = lazy(() => import("./Report"));
const CanvaComponent = lazy(() => import("./CanvaComponent"));
const Screen_Admin = lazy(() => import("./Admin/Screen_Admin"));
const Assign_Screens = lazy(() => import("./Admin/Assign_Screens"));
const Assign_Screens_To_User = lazy(() => import("../components/admin/Assign_Screens_To_User"));
const Access_Permissions = lazy(() => import("./Admin/Access_Permissions"));
const MyScreen = lazy(() => import("./MyScreen"));
const MyAds = lazy(() => import("./MyAds"));
const ScreenDashboard = lazy(() => import("./ScreenDashboard"));
const Scheduler = lazy(() => import("./Scheduler"));
const Playlist = lazy(() => import("./Playlist"));
const RestaurantPlaylist = lazy(()=>import("./RestaurantPlaylist"));
const DesignLayout = lazy(() => import("./DesignLayout"));
const Design = lazy(() => import("./Design"));
const OnOffReport = lazy(() => import("./OnOffReport"));
const AdReport = lazy(() => import("./AdReport_lambda"));//import("./AdReport"));
const WeeklyAdSummaryReport = lazy(() => import("./WeeklyAdSummaryReport"));
const CovidReport = lazy(() => import("./CovidReport"));
const CustomerReport = lazy(() => import("./CustomerReport"));
const CustomerDetailReport = lazy(() => import("./CustomerDetailReport"));
const PlaylistReport = lazy(() => import("./PlaylistReport"));
const ActiveScreensReport = lazy(() => import("./ActiveScreensReport"));
const AdGroup_Admin = lazy(() => import("./Admin/AdGroup_Admin"));
const Ad_Management = lazy(() => import("./Admin/Ad_Management"));
const ScreenSaver_Management = lazy(() =>
  import("./Admin/ScreenSaver_Management")
);
const SocialMedia_Management = lazy(() =>
  import("./Admin/SocialMedia_Management")
);
const SocialMedia_Management_Review = lazy(() =>
  import("./Admin/SocialMedia_Management_Review")
);
const User_Management = lazy(() => import("./Admin/User_Management"));
const DeviceIP_Management = lazy(() => import("./Admin/DeviceIP_Management"));
const SoftwareVersion_Update = lazy(() =>
  import("./Admin/SoftwareVersion_Update")
);
const Schedule_Management = lazy(() => import("./Admin/Schedule_Management"));
const SingleScreenSelector = lazy(() => import("./Admin/SingleScreenSelector"));
const ScreenType_Management = lazy(() =>
  import("./Admin/ScreenType_Management")
);
const Tags_Management = lazy(() => import("./Admin/Tags_Management"));
const Sync_Management = lazy(() => import("./Admin/Sync_Management"));
const Location_Management = lazy(() => import("./Admin/Location_Management"));
const ConfRoomScheduler = lazy(() => import("./ConfRoomScheduler"));
const GenerateGraph = lazy(() => import("./Admin/GenerateGraph"));
const Split_Screen_Management = lazy(() =>
  import("./Admin/Split_Screen_Management")
);
const LoginDetails = lazy(() => import("./Admin/LoginDetails"));
const TwoStepLogin = lazy(() => import("./Admin/TwoStepLogin"));
const Select_User_For_SWVersion = lazy(() =>
  import("./Admin/Select_User_For_SWVersion")
);
const Json_File_Upload = lazy(() =>
  import("./Admin/Json_File_Upload")
);
const Playlist_Mapping_Upload = lazy(()=>
import("./Admin/Playlist_Mapping_Upload")
);
const Role_Management = lazy(() => import("./Admin/Role_Management"));
const Assign_Screen_SyncGroup = lazy(() =>
  import("./Admin/Assign_Screen_SyncGroup")
);
const Image_Editor = lazy(() => import("./Admin/Image_Editor")); //for testing Canva
const SyncGroupLayout = lazy(() => import("./Admin/SyncGroupLayout"));
const ScreenLayoutSettings = lazy(() =>
  import("./Admin/Screen_Layout_Settings")
);
const AddWidgets = lazy(() => import("./Admin/Add_Widgets"));
const AddWidgetsManagement = lazy(() =>
  import("./Admin/Add_Widgets_Management")
);
const OffScreenDetails = lazy(() => import("./Admin/OffScreenDetails"));
const RedirectToFodxpert = lazy(() => import("./RedirectToFodxpert"));
const Account_Management = lazy(() => import('./Admin/Account_Management'));

export default class Layout extends Component {
  constructor(props) {
    super(props);
    this.redirectToFodxpert = false;
    this.isTimedOut = false;
    this.state = {
      builder: false,
      //for session timeout in milliseconds
      timeout: 1000 * 10 * 60,
      showModal: false,
      userLoggedIn: false,
      progressBarVisible:"hidden",
    };
    this.closeHandler = this.closeHandler.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    //for session timeout end
  }

  componentDidMount(){
    console.log("in component did mount of layout!")
    var hash = window.location.hash.substr(1);

    var result = hash.split('?').reduce(function (res, item) {
        var parts = item.split('=');
        res[parts[0]] = parts[1];
        return res;
    }, {});

    //console.log("**result-"+JSON.stringify(result))
    
    if(result.code){
      this.redirectToFodxpert = true;
      //console.log("setting redirect to true!")
    }
  }

  openProgressBar = (visibility) =>{
    console.log("setting Ad upload progress bar to visible!!")
    this.setState({progressBarVisible:visibility});
  }

  _onAction(e) {
    //console.log('user did something', e)
    this.isTimedOut = false;
  }

  _onActive(e) {
    //console.log('user is active', e)
    this.isTimedOut = false;
  }

  _onIdle(e) {
    //console.log('user is idle', e)
    const isTimedOut = this.isTimedOut;
    if (isTimedOut) {
      this.props.history.push("/logout");
    } else {
      this.setState({ showModal: true });
      this.idleTimer.reset();
      this.isTimedOut = true;
      // this.setState({isTimedOut: true})
    }
  }
  closeHandler() {
    // console.log("in handle close")
    this.setState({ showModal: false });
  }

  handleLogout() {
    // console.log("in handle logout")
    this.setState({ showModal: false });
    this.props.history.push("/logout");
  }

  render() {
    return (
      <div class="h-100">
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />
        {this.state.showModal ? (
          <IdleTimeOutModal
            closeHandler={() => this.closeHandler()}
            handleLogout={() => this.handleLogout()}
          />
        ) : null}
        {JSON.parse(localStorage.getItem("userObj")).userName!=='fodxpert_interface' 
        && !this.redirectToFodxpert?
        <Navbar />:null}

        <div class="content-wrapper no-scroll">
          <Suspense fallback={<PageLoader />}>
            <Switch>
              <Route exact path="/overview" component={Dashboard} />
              <Route
                path="/admin/screenmanagement"
                component={Screen_Admin}
                exact
              />
              <Route
                path="/admin/adgroupmanagement"
                component={AdGroup_Admin}
                exact
              />
              <Route
                path="/admin/admanagement/:selectedAd"
                component={Ad_Management}
                exact
              />
              {/* <Route path="/admin/admanagement" component={Ad_Management} exact/> */}
              <Route
                path="/admin/screensavermanagement"
                component={ScreenSaver_Management}
                exact
              />
              <Route
                path="/admin/screentypemanagement"
                component={ScreenType_Management}
                exact
              />
              <Route
                path="/admin/socialmediamanagement"
                component={SocialMedia_Management}
                exact
              />
              <Route
                path="/admin/socialmediamanagement_review"
                component={SocialMedia_Management_Review}
                exact
              />
              <Route path="/admin/accountmanagement" component={Account_Management} exact/>
              <Route
                path="/adminoptions/schedulemanagementscreens"
                component={SingleScreenSelector}
                exact
              />
              <Route
                path="/adminoptions/schedulemanagement/:screenId/:source"
                component={Schedule_Management}
                exact
              />
              <Route
                path="/adminoptions/assignscreens"
                component={Assign_Screens}
                exact
              />
              <Route
                path="/assignscreenstouser"
                component={Assign_Screens_To_User}
                exact
              />
              <Route
                path="/adminoptions/accesspermissions"
                component={Access_Permissions}
                exact
              />
              <Route
                path="/adminoptions/assignSWversiontoscreens"
                component={Select_User_For_SWVersion}
                exact
              />
              <Route
                path="/adminoptions/jsonfileupload"
                component={Json_File_Upload}
                exact
              />
              <Route
                path="/adminoptions/playlistmappingupload"
                component={Playlist_Mapping_Upload}
                exact
              />              
              <Route
                path="/adminoptions/rolemanagement"
                component={Role_Management}
                exact
              />
              <Route
                path="/admin/tagsmanagement"
                component={Tags_Management}
                exact
              />
              <Route
                path="/admin/syncmanagement"
                component={Sync_Management}
                exact
              />
              <Route
                path="/admin/locationmanagement"
                component={Location_Management}
                exact
              />
              <Route path="/generateGraph" component={GenerateGraph} exact />
              <Route
                path="/admin/splitscreenmanagement"
                component={Split_Screen_Management}
                exact
              />
              <Route
                path="/admin/logindetails"
                component={LoginDetails}
                exact
              />
              <Route
                path="/admin/twosteplogin"
                component={TwoStepLogin}
                exact
              />
              <Route
                path="/admin/assignscreensyncgroup/:syncgroupname"
                component={Assign_Screen_SyncGroup}
                exact
              />
              <Route path="/admin/imageeditor" component={Image_Editor} exact />
              {/* Added for Canva */}

              <Route
                path="/reports/onoffreport"
                component={OnOffReport}
                exact
              />
              <Route
                path="/reports/weeklyadsummaryreport"
                component={WeeklyAdSummaryReport}
                exact
              />
              <Route
                path="/reports/covidreport"
                component={CovidReport}
                exact
              />
              <Route
                path="/reports/customerreport"
                component={CustomerReport}
                exact
              />
              <Route
                path="/reports/customerdetailreport"
                component={CustomerDetailReport}
                exact
              />
              <Route
                path="/reports/playlistreport"
                component={PlaylistReport}
                exact
              />
              <Route path="/reports/adreport" component={AdReport} exact />
              <Route path="/reports/activescreensreport" component={ActiveScreensReport} exact />
              

              {JSON.parse(localStorage.getItem("userObj")).role.toString() !=
              "003" ? (
                <Route
                  path="/adminoptions/usermanagement"
                  component={User_Management}
                  exact
                />
              ) : null}
              {JSON.parse(localStorage.getItem("userObj")).role.toString() ==
              "000" ? (
                <Route
                  path="/admin/deviceIPmanagement"
                  component={DeviceIP_Management}
                  exact
                />
              ) : null}
              {JSON.parse(localStorage.getItem("userObj")).role.toString() ==
              "000" ? (
                <Route
                  path="/admin/softwareversionupdate"
                  component={SoftwareVersion_Update}
                  exact
                />
              ) : null}

              <Route path="/admin" component={Admin} exact />
              <Route path="/adminoptions" component={AdminOptions} exact />
              <Route path="/restuser" component={RestUser} exact />
              <Route path="/scheduler" component={Scheduler} exact />
              <Route
                path="/confroomscheduler"
                component={ConfRoomScheduler}
                exact
              />
              <Route path="/playlist" component={Playlist} exact />
              <Route path="/restaurantplaylist" component={RestaurantPlaylist} exact />
              <Route path="/designlayout" component={DesignLayout} exact />
              <Route path="/design" component={Design} exact />
              <Route path="/reports" component={Report} exact />
              <Route
                path="/admin/syncgrouplayout/:syncgroupname"
                component={SyncGroupLayout}
                exact
              />
              <Route path="/myscreen/:index" component={MyScreen} exact />
              <Route path="/canvacomp" component={CanvaComponent} />
              <Route
                path="/admin/screenlayoutsettings"
                component={ScreenLayoutSettings}
                exact
              />
              <Route path="/admin/addwidgets" component={AddWidgets} exact />
              <Route path="/admin/offScreenDetails" component={OffScreenDetails} exact />
              <Route
                path="/admin/addwidgetsmanagement"
                component={AddWidgetsManagement}
                exact
              />
              <Route path="/myads/" render={props => <MyAds openProgressBar = {(visibility)=>this.openProgressBar(visibility)} />} exact />
              <Route
                path="/myscreen/:id/:screen/:slave/:ticker/:widget/:layout/:syncGroup/:zone/:screenType"
                component={ScreenDashboard}
                exact
              />
              <Route path="/redirectToFodxpert" component={RedirectToFodxpert} exact />
              <Redirect
                from="/"
                to={
                  JSON.parse(
                    localStorage.getItem("userObj")
                  ).permissions.indexOf("overview") !== -1
                    ? "/overview"
                    : JSON.parse(
                        localStorage.getItem("userObj")
                      ).permissions.indexOf("myscreens") !== -1
                    ? "/myscreen/null"
                    : JSON.parse(
                        localStorage.getItem("userObj")
                      ).permissions.indexOf("myads") !== -1
                    ? "/myads"
                    : JSON.parse(
                        localStorage.getItem("userObj")
                      ).permissions.indexOf("playlist") !== -1
                    ? "/playlist"
                    :JSON.parse(
                        localStorage.getItem("userObj")
                      ).userRole=="RestaurantUserCreation"?
                      "/restuser"
                    : "/overview"
                }
              />
            </Switch>
          </Suspense>
        </div>

        {/* <div class="rounded" style={{backgroundColor:"#f5f3f3",zIndex:1500,position:"fixed",right:0,bottom:0,height:"25px",padding:5,width:"auto",maxWidth:"200px",minWidth:"100px"}}>
powered by&nbsp;<span style={{color:"#66CBC9",letterSpacing:"0.5px"}}>intel</span><span style={{color:"#B3272D",letterSpacing:"0.5px"}}>i</span><span style={{color:"#66CBC9",letterSpacing:"0.5px"}}>sa</span>
</div> */}
        {this.state.progressBarVisible?
        <Ad_Upload_Progress progressBarVisibility={this.state.progressBarVisible} openProgressBar = {(visibility)=>this.openProgressBar(visibility)} />
        :null}
        <Footer />
      </div>
    );
  }
}
