import axios from "../../utils/axiosInstance";
export function fetchAdsList() {
  console.log("fetchAdsList", "starttime==" + Date.now());

  // return (dispatch)=>{
  //   dispatch({type:"FETCH_ADSLIST"})
  //   var loggedInUserRole = JSON.parse(localStorage.getItem("userObj")).role;
  //   axios.post("/api/getAdsList",{loggedInUserRole}).then(function (response){
  //       dispatch({type:"FETCH_ADSLIST_SUCCESS",payload:response.data})
  //     })

  // }
  return (dispatch) => {
    dispatch({ type: "FETCH_ADSLIST" });
    var userID = JSON.parse(localStorage.getItem("userObj")).userID;
    var loggedInUserRole = JSON.parse(localStorage.getItem("userObj")).role;
    var manager_id = JSON.parse(localStorage.getItem("userObj")).manager_id;
    var arrTemp = [];
    // if (loggedInUserRole == "001" || loggedInUserRole == "002") {
      axios
      .post("/api/admin/getUsersInHierarchy", { userID })
   // .post("/api/admin/getLoggedInUserSubordinates", { loggedInUserRole })
   .then(function (response) {
     var arrTemp = response.data;
     console.log("arrTemp="+JSON.stringify(arrTemp));
     console.log("FETCH_ADSLIST got logged in user subordinates", "endTime==" + Date.now());
          //for 002 user, should see ads and adgroups of level 1 and level 3
          // loggedInUserRole == "002" ? arrTemp.push(manager_id) : null;
          console.log("arrTemp=" + arrTemp);
          axios
            .post("/api/getAdsList", { loggedInUserRole, arrTemp })
            .then(function (response) {
              dispatch({
                type: "FETCH_ADSLIST_SUCCESS",
                payload: response.data,
              });
            });
        });
    // }
    //  else {
    //   axios
    //     .post("/api/getAdsList", { loggedInUserRole, arrTemp: [manager_id] })
    //     .then(function (response) {
    //       dispatch({ type: "FETCH_ADSLIST_SUCCESS", payload: response.data });
    //     });
    // }
    console.log("fetchAdsList", "endtime==" + Date.now());
  };
}

export function deletemultiAd(arrdata) {
  console.log("deletemultiAd", "starttime==" + Date.now());
  return (dispatch) => {
    //dispatch({type:"FETCH_ADSLIST"})
    axios
      .post("/api/admin/deletemultiAd", { adListArr: arrdata })
      .then(function (response) {
        console.log(response);
        dispatch({ type: "DELETEALL_FROM_ADSLIST_SUCCESS", payload: arrdata });
        console.log("deletemultiAd", "endtime==" + Date.now());
      });
  };
}

export function deleteAd(obj) {
  return (dispatch) => {
    //dispatch({type:"FETCH_ADSLIST"})
    axios.post("/api/admin/deleteAd", obj).then(function (response) {
      dispatch({ type: "DELETE_FROM_ADSLIST_SUCCESS", payload: obj.id });
    });
  };
}
export function saveAdDetails(obj) {
  return (dispatch) => {
    //dispatch({type:"FETCH_ADSLIST"})
    axios.post("/api/admin/saveAdDetails", { obj }).then(function (response) {
      dispatch({
        type: "SAVE_AD_SUCCESS",
        newObj: response.data,
        payload: obj._id,
      });
    });
  };
}
export function fetchMyAdsInfo(selectedItem) {
  return (dispatch) => {
    dispatch({ type: "FETCHING_MYADS" });
    axios
      .post("/api/getMyAds", { adName: selectedItem.fileName })
      .then(function (response) {
        dispatch({
          type: "FETCH_MYADS_SUCCESS",
          payload: response.data,
          selectedItem,
        });
      });
  };
}

export function fetchExpiredAdsInfo(selectedItem) {
  return (dispatch) => {
    dispatch({ type: "FETCHING_EXPIREDADS" });
    axios.post("/api/getExpiredAds").then(function (response) {
      dispatch({ type: "FETCHING_EXPIREDADS_SUCCESS", payload: response.data });
    });
  };
}


export function updateStatusOfUploadedFiles(filesArr){
  return (dispatch)=>{
    dispatch({type:"UPDATESTATUS_UPLOADEDFILES"})
    axios.post("/api/updateStatusOfUploadedFiles",{filesArr}).then(function (response){  
      console.log("**response.data="+JSON.stringify(response.data));
        localStorage.setItem('filesArr',JSON.stringify(response.data));
        dispatch({type:"UPDATESTATUS_UPLOADEDFILES_SUCCESS",payload:response.data})
      })

  }
}